// assets/css/oauth2.scss

// customize some Bootstrap variables
// $primary: darken(#428bca, 20%);

// the ~ allows you to reference things in node_modules


// @import "import.css";
@import "bootstrap.css";
@import "jquery-ui-1.10.3.custom.css";
@import "font-awesome";
@import "text-security";
@import "form.scss";
@import "oauth2.css";
