.form-group {
    position: relative;
}

textarea.form-control ~ label,
select.form-control ~ label,
.form-control[type="text"] ~ label, 
.form-control[type="password"] ~ label, 
.form-control[type="email"] ~ label, 
.form-control[type="number"] ~ label {
    position: absolute;
    top: 9px;
    left: 15px;
    padding: 0.1rem 0;
    pointer-events: none;
    color: #999;
    transition: 0.2s;
    z-index: 3;  
}

.form-control[type="text"], 
.form-control[type="password"], 
.form-control[type="email"], 
.form-control[type="number"] {
   height: 40px !important;
}

textarea.form-control,
select.form-control{
    padding-top: 10px;
}

.form-control:focus {
    outline: none;
    border: 1px solid #65b0cc;
}

.form-control::placeholder {
    color: transparent;
}
  
.form-control:focus ~ label,
.form-control:not(:placeholder-shown) ~ label,
.form-controlt:not([value=""]) ~ label 
.form-control:not([value]) ~ label {
    top: -.9rem;
    left: 10px;
    background-color: white;
    color: #65b0cc;
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    z-index: 3;
}

.form-control .error { border: 1px solid red }

.form-group input:focus {
    outline: none;
    border: 1px solid #65b0cc;
}

.btn-primary { 
    color: #fff;
    background-color: #65b0cc;
    border-color: #65b0cc;
}

.btn-primary:hover { 
    color: #fff;
    background-color: #65b0cc;
    border-color: #65b0cc;
}

.input-group-btn {
    vertical-align: top;
}

.input-group-btn .btn {
    padding: 10px 15px;
}