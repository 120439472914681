body {
    background: #f4f4f4;
    border: none;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    font-size: 13px;
    color: #555;
    margin: 0;
    padding: 0;
    position: relative;
  }

.oauth2-box {
    border-radius: 20px;
    padding: 48px 40px 36px;
    max-width: 400px;
    transition: .2s;
    margin: 20% auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #fff;
}

.oauth2-header {
    color: #555555;
    font-size: 2em;
    text-align: center; 
    margin-bottom: 25px;
}
.oauth2-brand {
    text-align: center;
    margin-bottom: 30px;
}

.oauth2-box input[type="submit"], 
.oauth2-box button,
.oauth2-box .btn {
    width: 100%;
}

.profile-box {
    border-radius: 20px;
    transition: .2s;
    margin: 20% auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #fff;
}

.profile-box-content {
    padding: 48px 40px 36px;
}

.profile-box-header {
    background-color: #65b0cc;
    color: #fff;
    font-size: 1.5em;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 10px;
    font-weight: bold;
}
